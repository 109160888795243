@use "@wojo/ui/mixins" as *;

.container {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.wrapper {
    > div:first-child {
        padding-inline: 0;
        > div:first-child {
            margin-inline: 0;
        }
    }
}
